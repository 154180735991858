import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_2 = ["onInput", "value"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createBlock(_component_Datatable, {
    "enable-items-per-page-dropdown": _ctx.tableData.length > 10,
    "table-data": _ctx.tableData,
    "table-header": _ctx.tableHeader
  }, {
    "cell-radio": _withCtx(({ row: exploitation }) => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          onInput: ($event: any) => (_ctx.newexploitationChoice(exploitation.id)),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.exploitationChoice) = $event)),
          value: exploitation.id,
          class: "form-check-input",
          type: "radio"
        }, null, 40, _hoisted_2), [
          [_vModelRadio, _ctx.exploitationChoice]
        ])
      ])
    ]),
    "cell-name": _withCtx(({ row: exploitation }) => [
      _createElementVNode("div", {
        class: "text-hover-primary cursor-pointer",
        onClick: ($event: any) => (_ctx.newexploitationChoice(exploitation.id))
      }, _toDisplayString(exploitation.name), 9, _hoisted_3)
    ]),
    "cell-date_created": _withCtx(({ row: exploitation }) => [
      _createTextVNode(_toDisplayString(_ctx.parseDate(exploitation.exploitation_versions[0].date_created)), 1)
    ]),
    _: 1
  }, 8, ["enable-items-per-page-dropdown", "table-data", "table-header"]))
}