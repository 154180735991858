
import { parseDate } from "@/core/helpers/globalJaya.ts";
import { computed, defineComponent, onUpdated, ref } from "vue";
import { useStore } from "vuex";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default defineComponent({
  name: "exploitations-list",
  emits: ["new-selection-factor"],
  components: {
    Datatable,
  },
  props: {
    selectedExploitation: { type: Number },
  },
  setup(props, { emit }) {
    const store = useStore();

    let exploitationChoice = ref<number | undefined>(
      props.selectedExploitation
    );

    const tableHeader = ref([
      {
        key: "radio",
        sortable: false,
      },
      {
        name: "Exploitation",
        key: "name",
        sortable: true,
      },
      {
        name: "Date de création",
        key: "date_created",
        sortable: true,
      },
    ]);

    const tableData = computed(() => store.getters.getExploitations);

    onUpdated(() => {
      exploitationChoice.value = props.selectedExploitation;
    });

    const newexploitationChoice = (choiceId) => {
      exploitationChoice.value = choiceId;
      emit(
        "new-selection-factor",
        tableData.value.find((e) => e.id === exploitationChoice.value)
          .exploitation_versions[0].id
      );
    };

    return {
      exploitationChoice,
      tableHeader,
      tableData,
      newexploitationChoice,
      parseDate: parseDate,
    };
  },
});
