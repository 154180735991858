
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
} from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { Diagnostic } from "@/store/modules/DiagnosticsModule";

import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import ExploitationsList from "@/components/exploitations/ExploitationsList.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";
import { ErrorMessage, Field } from "vee-validate";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import DiagnosticsList from "@/components/diagnostic/DiagnosticsList.vue";

export default defineComponent({
  name: "new-diagnostic",
  components: {
    DiagnosticsList,
    Field,
    ErrorMessage,
    ExploitationsList,
    CardTitleJaya,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    let fromScratch = ref<boolean>(true);
    let editingDateEvaluated = ref<string>(
      new Date().toISOString().substr(0, 10)
    );

    // Stepper element
    const _stepperObj = ref<StepperComponent | null>(null);
    let newDiagnosticStepper = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    let newDiagnostic = ref<Diagnostic>({
      id: -1,
      date_evaluated: new Date().toISOString(),
      is_real: false,
      finished: false,
      exploitation: -1,
      exploitation_id: -1,
      date_created: new Date().toISOString(),
      results: [],
    });

    onMounted(() => {
      store
        .dispatch(Actions.FETCH_EXPLOITATIONS)
        .then(() => {
          if (store.getters.getExploitations.length === 0) {
            Swal.fire({
              title: "Aucune exploitation !",
              html: "Un diagnostic biodiversité porte sur une exploitation donnée. Veuillez d'abord créer une exploitation.",
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: "J'ai compris",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              router.push({ name: "new-exploitation" });
            });
          } else {
            if (store.getters.getExploitations.length === 1) {
              newDiagnostic.value.exploitation =
                store.getters.getExploitations[0].exploitation_versions[0].id;
              newDiagnostic.value.exploitation_id =
                store.getters.getExploitations[0].id;
            }
            store.dispatch(Actions.FETCH_DIAGNOSTICS).then(() => {
              _stepperObj.value = StepperComponent.createInsance(
                newDiagnosticStepper.value as HTMLElement
              );

              _stepperObj.value?.goto(0);
              _stepperObj.value?.goto(1);

              setCurrentPageTitle("Nouveau diagnostic");
            });
          }
        })
        .catch(() => {
          router.push({ name: "diagnostic-list" });
        });
    });

    onBeforeUnmount(() => {
      newDiagnosticStepper.value = null;
    });
    const canDiagnosticBeReal = computed(() => {
      return store.getters.currentUser.professional_farmer;
    });

    const maxDateEvaluated = computed(() => {
      return newDiagnostic.value.is_real
        ? new Date().toISOString().substr(0, 10)
        : undefined;
    });
    const checkMaxDateEvaluated = () => {
      if (
        newDiagnostic.value.is_real &&
        new Date(editingDateEvaluated.value) > new Date()
      ) {
        editingDateEvaluated.value = new Date().toISOString().substr(0, 10);
      }
    };

    const diagnosticList = computed(() => store.getters.getDiagnostics);

    const canCreateDiagnosticFromExisting = computed(() => {
      return diagnosticList.value.length > 0;
    });

    const canCreateDiagnostic = computed(() => {
      return store.getters.getExploitations.length > 0;
    });
    const stepValid = computed(() => {
      if (currentStepIndex.value === 1 && !canDiagnosticBeReal.value) {
        return newDiagnostic.value.exploitation >= 0;
      }
      if (currentStepIndex.value === 2 && canDiagnosticBeReal.value) {
        return newDiagnostic.value.exploitation >= 0;
      }
      return true;
    });
    const lastDiagnostic = computed(() => {
      let recentDiagnostics = store.getters.getDiagnostics
        .filter(
          (diagnostic) =>
            diagnostic.exploitation === newDiagnostic.value.exploitation
        )
        .sort((a, b) =>
          new Date(a.date_created).getTime() >
          new Date(b.date_created).getTime()
            ? -1
            : 1
        );
      if (recentDiagnostics.length) {
        return recentDiagnostics[0];
      }
      return null;
    });

    const isLastDiagnosticRecent = computed(() => {
      if (
        newDiagnostic.value.exploitation >= 0 &&
        lastDiagnostic.value &&
        lastDiagnostic.value.date_created
      ) {
        let today = new Date();
        let lastDiagnosticDate = new Date(lastDiagnostic.value.date_created);
        let timeInMilisec = today.getTime() - lastDiagnosticDate.getTime();
        if (Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24)) <= 3) {
          return true;
        }
      }
      return null;
    });

    const setDiagnosticChoice = (choiceId) => {
      newDiagnostic.value = diagnosticList.value.find(
        (diag) => diag.id === choiceId
      );
      newDiagnostic.value.exploitation = store.getters.getExploitations.find(
        (e) => e.id === newDiagnostic.value.exploitation_id
      ).exploitation_versions[0].id;
    };

    const setExploitationChoice = (choiceId) => {
      newDiagnostic.value.exploitation = choiceId;
    };

    const checkForRecentDiagnostics = () => {
      if (!isLastDiagnosticRecent.value || !fromScratch.value) {
        addDiagnostic();
      } else {
        Swal.fire({
          text: "Vous avez récement commencé un diagnostic sur  l'exploitation. Souhaitez-vous le reprendre ?",
          icon: "warning",
          confirmButtonText: "Reprendre le diagnostic",
          showCancelButton: true,
          cancelButtonText: "Créer un nouveau diagnostic",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-light-secondary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            router.push({
              name: "edit-diagnostic",
              params: { diagnosticId: lastDiagnostic.value.id },
            });
          } else if (result.dismiss === "cancel") {
            addDiagnostic();
          } else {
            const validatePrompt = new Modal(
              document.getElementById("validatePrompt")
            );
            validatePrompt.show();
          }
        });
      }
    };

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }
      return _stepperObj.value.totatStepsNumber;
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }
      currentStepIndex.value--;
      _stepperObj.value.goPrev();
    };

    const nextStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value++;
      _stepperObj.value.goNext();
    };

    const addDiagnostic = async () => {
      const response = await store.dispatch(Actions.ADD_DIAGNOSTIC, {
        is_real: newDiagnostic.value.is_real,
        exploitation: newDiagnostic.value.exploitation,
        date_evaluated: new Date(editingDateEvaluated.value),
        date_created: new Date().toISOString(),
        finished: false,
        results: [],
      });
      if (!fromScratch.value && newDiagnostic.value.results) {
        await Promise.all(
          newDiagnostic.value.results.map((result) => {
            return store.dispatch(Actions.ADD_DIAGNOSTIC_RESULT, {
              diagnostic: response.id,
              level: result.level,
            });
          })
        );
      }

      await router.push({
        name: "edit-diagnostic",
        params: { diagnosticId: response.id },
      });
    };

    return {
      newDiagnosticStepper,
      previousStep,
      nextStep,
      totalSteps,
      currentStepIndex,
      diagnosticList,
      fromScratch,
      newDiagnostic,
      editingDateEvaluated,
      maxDateEvaluated,
      checkMaxDateEvaluated,
      canCreateDiagnostic,
      canDiagnosticBeReal,
      canCreateDiagnosticFromExisting,
      lastDiagnostic,
      isLastDiagnosticRecent,
      setDiagnosticChoice,
      setExploitationChoice,
      checkForRecentDiagnostics,
      addDiagnostic,
      stepValid,
    };
  },
});
